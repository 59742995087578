import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

export const Image = ({ image, className, loading = `lazy`, alt }) => {
  if (image && image.childImageSharp && image.childImageSharp.gatsbyImageData) {
    return (
      <GatsbyImage
        image={image.childImageSharp.gatsbyImageData}
        objectFit="contain"
        loading={loading}
        className={className}
        alt={alt}
      />
    );
  } else if (image && image.publicURL) {
    return <img src={image.publicURL} className={className} alt={alt} />;
  } else if (image) {
    return <img src={image} className={className} alt={alt} />;
  } else {
    return false;
  }
};

export default Image;
