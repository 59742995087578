import React from 'react'
import wreathLeft from '../../../img/wreath-left.svg'
import wreathRight from '../../../img/wreath-right.svg'

export const FooterAwards = ({ awardsIntro, awards }) => {
  return (
    <aside className="awards bg-dark-primary py-5">
      <div className="container-fluid">
        <div className="row contained-xl align-content-center justify-content-center align-items-center text-center">
          <div className="col-lg-10">
            <h3 className="h4 mb-5">{awardsIntro}</h3>
            <div className="row justify-content-center">
              {awards.map(function(award, i) {
                return (
                  <div
                    className="col-6 col-md-4 col-lg-3 mb-3"
                    key={'footer_awards_' + i}
                  >
                    <div className="awards-award">
                      <img src={wreathLeft} className="before" alt="{" />
                      <img src={wreathRight} className="after" alt="}" />
                      <h4 className="awards-award_name">{award.name}</h4>
                      <p>{award.description}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </aside>
  )
}

export default FooterAwards
