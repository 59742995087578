import React, { useState } from "react";
import { Link } from "gatsby";
import logoWhite from "./../../img/logo-white.svg";
import logoMain from "./../../img/logo-main.svg";
import HamburgerMenu from "react-hamburger-menu";
// import Image from "../Hacks/Image";
import CSImage from "../../../static/img/marketing-solutions.png";

export const NavBar = function ({ darkMenu, alert, social, contact }) {
  const [open, setOpen] = useState(false);
  const [openInner, setOpenInner] = useState(false);

  return (
    <div className={`${alert.alertEnable ? "with-alert" : ""}`}>
      <div
        className={`navbar-fixed-top ${alert.alertEnable ? "with-alert" : ""}`}
      >
        {alert.alertEnable && (
          <div className={"headeralert bg-" + alert.alertBackground + " py-3"}>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <span className="headeralert-text">{alert.alertMessage}</span>
                <Link
                  activeClassName="active"
                  cover={`true`}
                  direction="right"
                  bg="#1F1A3C"
                  to={alert.alertLink}
                  className={
                    "btn-" + alert.alertButtonColor + " btn btn-sm ml-2"
                  }
                >
                  {alert.alertTitle}
                </Link>
              </div>
            </div>
          </div>
        )}

        <nav
          className={`navbar-expand-lg ${open ? "bg-primary" : ""}`}
          id="mainnavbar"
        >
          <div className="navbar py-3 py-md-4 py-lg-3">
            <div className="navbar-brand">
              <Link
                activeClassName="active"
                cover={`true`}
                direction="right"
                bg="#1F1A3C"
                to="/"
                className="navbar-item"
              >
                <figure className="image">
                  {darkMenu === "true" || open ? (
                    <img src={logoWhite} alt="Databowl" className="brand" />
                  ) : (
                    <React.Fragment>
                      <img
                        src={logoMain}
                        alt="Databowl"
                        className="brand dark"
                      />
                      <img
                        src={logoWhite}
                        alt="Databowl"
                        className="brand light"
                      />
                    </React.Fragment>
                  )}
                </figure>
              </Link>
            </div>

            <div className="navbar-toggler">
              <HamburgerMenu
                isOpen={open}
                menuClicked={() => setOpen(!open)}
                width={24}
                height={16}
                strokeWidth={2.5}
                rotate={0}
                color={`
                ${
                  darkMenu === "true"
                    ? open
                      ? "white"
                      : "white"
                    : open
                    ? "white"
                    : "#f44676"
                }
              
            `}
                borderRadius={100}
                animationDuration={0.5}
              />
            </div>

            <div
              className={`collapse navbar-collapse ${
                darkMenu === "true" ? `navbar-dark` : `navbar-light`
              }  ml-auto flex-nowrap ${open && `show`} `}
              id="databowlNavbar"
            >
              <ul id="menu-main-menu" className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link
                    activeClassName="active"
                    cover={`true`}
                    direction="right"
                    bg="#1F1A3C"
                    className="nav-link d-lg-none"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className={`nav-link dropdown-toggle ${
                      openInner === "solutions" ? "activeopen" : ""
                    }`}
                    href="#menu-main-menu"
                    onClick={() => {
                      setOpenInner(
                        openInner === "solutions" ? false : "solutions"
                      );
                    }}
                  >
                    Solutions
                  </a>
                  <div
                    className={`dropdown-menu mb-4 mb-lg-0 ${
                      openInner === "solutions" ? "d-mobile-block" : ""
                    }`}
                  >
                    <div className="dropdown-menu-inner p-0 pb-lg-4 pt-lg-2">
                      <div className="container-fluid">
                        <div className="row w-100 contained-xl justify-content-center">
                          <div className="col-lg-10">
                            <div className="row pt-lg-4 justify-content-center">
                              <div className="col-lg-4 offset-lg-2 px-0 px-lg-2">
                                <h6 className="text-white mb-2 text-uppercase">
                                  Products
                                </h6>
                                <hr className="border col-1 border-secondary accent-2 mb-2 mt-0 d-inline-block mx-auto" />
                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/solutions-for-lead-management/"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-chart-network text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Lead Management
                                        <p className="small text-white">
                                          Automate, streamline and maximise your
                                          lead buying performance.
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/solutions-for-lead-generators/"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-inbox text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Lead Generation
                                        <p className="small text-white">
                                          Generate, identify, nurture, convert
                                          your quality leads and manage them.
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/solutions-for-enterprise/"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-tasks text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Enterprise Solutions
                                        <p className="small text-white">
                                          Full system, plus Tailored SLAs,
                                          Custom terms, additional Technical and
                                          creative support
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                {/* <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/solutions-for-design/"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-palette text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Design Services
                                        <p className="small text-white">
                                          Landing Pages, Competitions, Email
                                          Creative, Copy and Content.
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div> */}
                              </div>

                              {/* <div className="col-lg-3  px-0 px-lg-2 mb-4 offset-lg-1">
                                <h6 className="text-white mb-2 text-uppercase">
                                  Use Cases
                                </h6>
                                <hr className="border col-1 border-secondary accent-2 mb-2 mt-0 d-inline-block mx-auto" />

                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/solutions/teams/brands/"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-shield-alt text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Brands
                                        <p className="small text-white">
                                          Protect your brand and give your sales and marketing team the added tools they need
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/solutions/teams/affiliate-networks/"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-chart-network text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Affiliate Networks
                                        <p className="small text-white">
                                          Create unlimited campaigns for your partners, control and deliver your leads.
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/solutions/teams/agencies/"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-users text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Agencies
                                        <p className="small text-white">
                                          Host beautiful bespoke creative and generate, identify, nurture your leads.
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div> */}

                              <div className="col-lg-4  mt-4 mt-lg-0 px-0 px-lg-2 mb-lg-4 text-white">
                                <h6 className="text-white mb-2 text-uppercase">
                                  Pair us with
                                </h6>
                                <hr className="border col-1 border-secondary accent-2 mb-2 mt-0 d-inline-block mx-auto" />
                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/solutions/tools/crms/"
                                  >
                                    <i className="fal h5 fa-server small mr-2 text-secondary"></i>
                                    CRMs
                                  </Link>
                                </div>
                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/solutions/tools/diallers/"
                                  >
                                    <i className="fal h5 fa-phone small mr-2 text-secondary"></i>
                                    Diallers
                                  </Link>
                                </div>
                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/solutions/tools/databases/"
                                  >
                                    <i className="fal h5 fa-database small mr-2 text-secondary"></i>
                                    Databases
                                  </Link>
                                </div>
                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/solutions/tools/"
                                  >
                                    <i className="fal h5 fa-check-square small mr-2 text-secondary"></i>
                                    And More
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item">
                  <Link
                    activeClassName="active"
                    cover={`true`}
                    direction="right"
                    bg="#1F1A3C"
                    className="nav-link"
                    to="/pricing/"
                  >
                    Pricing
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className={`nav-link dropdown-toggle ${
                      openInner === "resources" ? "activeopen" : ""
                    }`}
                    href="#menu-main-menu"
                    onClick={() => {
                      setOpenInner(
                        openInner === "resources" ? false : "resources"
                      );
                    }}
                  >
                    Resources
                  </a>

                  <div
                    className={`dropdown-menu mb-4 mb-lg-0 ${
                      openInner === "resources" ? "d-mobile-block" : ""
                    }`}
                  >
                    <div className="dropdown-menu-inner p-0 pb-lg-4 pt-lg-2">
                      <div className="container-fluid">
                        <div className="row w-100 contained-xl justify-content-center">
                          <div className="col-lg-10">
                            <div className="row pt-lg-4">
                              <div className="col-lg-4 px-0 px-lg-2">
                                <h6 className="text-white mb-2 text-uppercase">
                                  Learn & Connect
                                </h6>
                                <hr className="border col-1 border-secondary accent-2 mb-2 mt-0 d-inline-block mx-auto" />

                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/blog/"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-bell text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Blog
                                        <p className="small text-white">
                                          Read our updates and industry insights
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <div className="mt-2">
                                  <a
                                    className="ml-0 px-0 stretched link"
                                    href="https://support.databowl.com"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-life-ring text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Help Centre
                                        <p className="small text-white">
                                          Learn how to setup, run, nurture,
                                          manage and maintain your instance.
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>

                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/newsletter/"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-newspaper text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Newsletter
                                        <p className="small text-white">
                                          Subscribe to our Newsletter
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>

                              <div className="col-lg-4 mt-4 mt-lg-0  px-0 px-lg-2 mb-4">
                                <h6 className="text-white mb-2 text-uppercase">
                                  Listen
                                </h6>
                                <hr className="border col-1 border-secondary accent-2 mb-2 mt-0 d-inline-block mx-auto" />
                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/podcast/"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-podcast text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Podcast
                                        <p className="small text-white">
                                          Check out our B2C Lead Gen Podcast
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>

                                <h6 className="text-white mb-2  mt-4 text-uppercase">
                                  Work With Us
                                </h6>
                                <hr className="border col-1 border-secondary accent-2 mb-2 mt-0 d-inline-block mx-auto" />
                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/solutions/referralprogram/"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-handshake text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Referrals Program
                                        <p className="small text-white">
                                          Earn money by helping us grow
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/current-vacancies/"
                                  >
                                    <div className="row">
                                      <div className="col-2 text-center">
                                        <i className="fal h5 fa-user-tie text-secondary"></i>
                                      </div>
                                      <div className="col-9 pl-0">
                                        Careers
                                        <p className="small text-white">
                                          Grow + Develop Your Career with us
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>

                              <div className="col-lg-3  px-0 px-lg-2 mb-lg-4 text-white">
                                <h6 className="text-white mb-2 text-uppercase">
                                  Customer Success
                                </h6>
                                <hr className="border col-1 border-secondary accent-2 mb-2 mt-0 d-inline-block mx-auto" />
                                <div className="mt-2">
                                  <Link
                                    activeClassName="active"
                                    cover={`true`}
                                    direction="right"
                                    bg="#1F1A3C"
                                    className="ml-0 px-0 stretched link"
                                    to="/success/"
                                  >
                                    <div className="row">
                                      <div className="col-lg-6 px-0 d-none d-lg-block">
                                        <img
                                          src={CSImage}
                                          className="w-100 img-fluid mb-4"
                                          alt=""
                                        />
                                      </div>
                                      <div className="col-lg-6 pr-0">
                                        <p className="text-white">
                                          Discover how businesses like you use
                                          Databowl to stop bad leads and support
                                          growth.
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="dropdown-menu p-0 pb-lg-3">
                    
                  </div> */}
                </li>

                <li className="nav-item">
                  <Link
                    activeClassName="active"
                    cover={`true`}
                    direction="right"
                    bg="#1F1A3C"
                    className="nav-link"
                    to="/about-us/"
                  >
                    About
                  </Link>
                </li>

                <li className="d-lg-none nav-item text-white mt-5 mb-4 pb-5">
                  <div className="h6 mb-3">CONTACT DETAILS</div>
                  <div className="">
                    T:{" "}
                    <a href={`tel:${contact.phone}`} className="">
                      {contact.phone}
                    </a>
                  </div>
                  <div className="mt-5">
                    <a
                      href={social.facebook}
                      rel="noreferrer"
                      target="_blank"
                      className="mr-4"
                    >
                      <i className="fab fa-facebook-f hover-secondary"></i>
                      <span className="sr-only">Facebook</span>
                    </a>
                    <a
                      href={social.instagram}
                      rel="noreferrer"
                      target="_blank"
                      className="mr-4"
                    >
                      <i className="fab fa-instagram hover-secondary"></i>
                      <span className="sr-only">Instagram</span>
                    </a>

                    <a
                      href="https://www.youtube.com/channel/UCzJTvbZBJJunOXFD1_CCImg"
                      rel="noreferrer"
                      target="_blank"
                      className="mr-4"
                    >
                      <i className="fab fa-youtube hover-secondary"></i>
                      <span className="sr-only">Youtube</span>
                    </a>
                    <a
                      href="http://bit.ly/AppleB2C"
                      rel="noreferrer"
                      target="_blank"
                      className="mr-4"
                    >
                      <i className="fab fa-apple hover-secondary"></i>
                      <span className="sr-only">Apple Podcasts</span>
                    </a>
                    <a
                      href="https://open.spotify.com/show/3WdHP5DkccckZktUun2MmG"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-spotify hover-secondary"> </i>
                      <span className="sr-only">Spotify</span>
                    </a>
                  </div>
                  <div className="row mt-4">
                    <div className="col-6">
                      <Link
                        cover={`true`}
                        direction="right"
                        bg="#1F1A3C"
                        to="/contact/"
                        className="nav-link btn btn-sm btn-secondary text-white ml-lg-3"
                      >
                        Get a Demo
                      </Link>
                    </div>
                    <div className="col-6">
                      <a
                        href="http://account.databowl.com"
                        rel="noreferrer"
                        target="_blank"
                        className="nav-link btn btn-sm btn-outline-secondary px-3 ml-lg-3"
                      >
                        Login
                      </a>
                    </div>
                  </div>
                </li>
                <li className="nav-button-item d-none d-lg-block nav-item">
                  <Link
                    cover={`true`}
                    direction="right"
                    bg="#1F1A3C"
                    to="/contact/"
                    className="nav-link btn btn-sm btn-secondary text-white ml-lg-3"
                  >
                    Get a Demo
                  </Link>
                </li>
                <li className="nav-button-item d-none d-lg-block nav-item">
                  <a
                    href="http://account.databowl.com"
                    rel="noreferrer"
                    target="_blank"
                    className="nav-link btn btn-sm btn-outline-secondary px-3 ml-lg-3"
                  >
                    Login
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
export default NavBar;
