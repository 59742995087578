import { graphql, useStaticQuery } from "gatsby";

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            alert {
              alertEnable
              alertLink
              alertTitle
              alertMessage
              alertButtonColor
              alertBackground
            }
            awards {
              awards {
                description
                name
              }
              awards_title
            }
            contact {
              address
              comp_vat
              contact_title
              email
              phone
            }
            meta {
              copyright
              footer_1_title
              footer_2_title
              footer_menu {
                title
                url
              }
              footer_menu {
                title
                url
              }
              footer_menu_2 {
                title
                url
              }
              siteDescription
              sitePageTitleSep
              blogDescription
              blogName
              successDescription
              successName
              siteImage
              siteTitle
              siteUrl
            }
            rescue {
              background_image
              intro
              link
              title1
              title2
            }
            social {
              apple
              facebook
              instagram
              linkedin
              spotify
              title
              twitter
              youtube
              blogNewsletterTitle
              blogNewsletterContent
              blogNewsletterFormId
              blogNewsletterPortalId
              footerNewsletterTitle
              footerNewsletterContent
              footerNewsletterFormId
              footerNewsletterPortalId
            }
          }
        }
      }
    `
  );
  return site.siteMetadata;
};

export default useSiteMetadata;
